import { QueryKeys } from 'queries';
import { useInfiniteQuery } from 'react-query';

import apiService from 'services/api/ApiService';

export const useCollectionById = (id: string) => {
  const fetchCollection = (params: { queryKey: {}; pageParam?: string }) => {
    let cursor = undefined;
    if (params.pageParam) cursor = params.pageParam.split('?cursor=')[1];

    return apiService.getGameCollection(id, cursor);
  };
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    QueryKeys.collectionbyid.all(id),
    fetchCollection,
    {
      enabled: !!id.length,
      getNextPageParam: lastPage => lastPage.loadMoreUrl,
    },
  );

  return {
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
    collection: data
      ? data?.pages
          ?.map(page => page.items)
          ?.filter(i => i !== undefined)
          .flat(1)
      : [],
  };
};
