import { QueryKeys } from 'queries';
import { useQuery } from 'react-query';

import apiService from 'services/api/ApiService';

export const useSearch = (term: string, collectionId?: string) => {
  const { data: searchData, isLoading } = useQuery(
    [QueryKeys.search.all(term, collectionId)],
    () => apiService.search(term, collectionId),
    {
      enabled: !!term,
    },
  );

  return {
    isLoading,
    searchData: searchData ?? { games: [], collections: [] },
  };
};
