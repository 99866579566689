import React from 'react';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Image from 'next/image';

import Link from 'components/@navigation/Link';

import { ContentCardInfo, ContentCardMediumProps } from '../Medium/ContentCardMedium';

// TODO:
// Inconsistent use of interface names. Have something like ContentCardBaseProps for every size.
interface ContentCardLargeProps extends ContentCardMediumProps {
  overline?: string;
  tagLine: string;
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: '0 !important',
    height: '100%',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 328,
    },
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[50],
    filter: 'drop-shadow(0px 1px 18px rgba(0, 0, 0, 0.12))',
    overflow: 'hidden',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'flex-start',
    textTransform: 'none',
    overflow: 'hidden',
  },
  imageContainer: {
    height: 185,
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
  },
  contentContainer: {
    minHeight: 72,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  overlay: {
    backgroundColor: 'rgba(2, 2, 53, 0.48)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  overContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  overline: {
    color: theme.palette.primary.contrastText,
    textAlign: 'left',
  },
  tagline: {
    color: theme.palette.primary.contrastText,
    fontStyle: 'normal',
    fontSize: 20,
    textAlign: 'left',
    lineHeight: 1.15,
  },
}));

const ContentCardLarge = ({
  onClick,
  href,
  bannerImage,
  title,
  blurImage,
  subTitle,
  image,
  overline,
}: ContentCardLargeProps) => {
  const classes = useStyles();

  return (
    <Button
      component={href ? Link : 'button'}
      href={href}
      classes={{ root: classes.button, label: classes.label }}
      onClick={href ? undefined : onClick}
      data-testid="game"
    >
      <Box className={classes.imageContainer}>
        <Image
          src={bannerImage}
          alt={`${title} image`}
          layout="fill"
          objectFit="cover"
          blurDataURL={blurImage}
          placeholder="blur"
        />
        <div className={classes.overlay} />
        <Box className={classes.overContainer}>
          {overline && (
            <Typography variant="overline" component="span" className={classes.overline}>
              {overline}
            </Typography>
          )}
          {/* <Typography variant="h6" component="span" className={classes.tagline}>
            {tagLine}
          </Typography> */}
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <ContentCardInfo title={title} subTitle={subTitle} image={image} blurImage={blurImage} />
      </Box>
    </Button>
  );
};

export default ContentCardLarge;
