import { useCollections, useNew, useWinRealPrizes } from 'queries';

import { DynamicCollectionType } from 'pages/collection/[slug]';

export const useDynamicCollections = (slug: string) => {
  const { collections } = useCollections({ enabled: slug === DynamicCollectionType.Collections });
  const { newCollection } = useNew(undefined, { enabled: slug === DynamicCollectionType.NewGames });
  // const { trendingNow } = useTrendingNow({
  //   enabled: slug === DynamicCollectionType.TrendingNow,
  // });
  const { winRealPrizes } = useWinRealPrizes(undefined, {
    enabled: slug === DynamicCollectionType.WinRealPrizes,
  });

  const matchDynamicCollection = (slug: string) => {
    switch (slug) {
      case DynamicCollectionType.NewGames:
        return newCollection;
      // case DynamicCollectionType.TrendingNow:
      //   return trendingNow;
      case DynamicCollectionType.WinRealPrizes:
        return winRealPrizes;
      default:
        return [];
    }
  };

  return {
    isCollection: slug === DynamicCollectionType.Collections,
    collections: collections ?? [],
    games: matchDynamicCollection(slug),
  };
};
