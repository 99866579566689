import React from 'react';

import { Box } from '@material-ui/core';
import { useDynamicCollections } from 'hooks';

import { SearchResponse } from 'services/api/definitions/search.definitions';

import CollectionList from 'components/@common/CollectionList';
import SearchEmptyState from 'components/@common/Search/SearchEmptyState';
import GameSection from 'components/@game-detail/Section';

interface Props {
  slug: string;
  searchData: SearchResponse;
  term?: string;
  onClear: () => void;
}

const DynamicCollection: React.FC<Props> = ({ slug, searchData, term, onClear }) => {
  const { collections, games, isCollection } = useDynamicCollections(slug);

  if (
    !!term &&
    ((isCollection && !searchData.collections.length) ||
      (!isCollection && !searchData.games.length))
  ) {
    return (
      <Box mt={10}>
        <SearchEmptyState value={term} onClear={onClear} />
      </Box>
    );
  }

  if (isCollection) {
    return (
      <CollectionList
        collection={!!term ? searchData.collections : collections}
        removePaddingLeft
      />
    );
  }

  return (
    <GameSection
      id="games"
      data={!!term ? searchData.games : games}
      variant="detail"
      imagesAboveFold
      disablePadding
    />
  );
};

export default DynamicCollection;
