import React from 'react';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { FormattedMessage, useIntl } from 'react-intl';
import { Routes } from 'routes';

import { generatePath } from 'utils/routes.utils';

import blurBase64 from 'constants/blurBase64';
import { CollectionInstance, GameTeaser } from 'services/api/definitions/collections.definitions';

import { DynamicCollectionType } from 'pages/collection/[slug]';

import CollectionList from 'components/@common/CollectionList';
import ContentCardLarge from 'components/@common/ContentCard/Large/ContentCardLarge';
import GameSection from 'components/@game-detail/Section';
import Link from 'components/@navigation/Link';

interface Props {
  newCollection: GameTeaser[];
  games: GameTeaser[];
  collections: CollectionInstance[];
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  onFetchNextPage: () => void;
}

const StaticDiscover = ({
  newCollection,
  games,
  collections,
  hasNextPage,
  isFetchingNextPage,
  onFetchNextPage,
}: Props) => {
  const intl = useIntl();

  return (
    <>
      {!!newCollection?.length && (
        <>
          <Box mb={4} pt={6}>
            <Typography variant="h5" component="h2">
              <FormattedMessage id="home.new.title" />
            </Typography>
          </Box>
          <ContentCardLarge
            overline={intl.formatMessage({ id: 'general.content.card.overline' })}
            bannerImage={newCollection[0].largeTeaserImage}
            title={newCollection[0].name}
            tagLine={newCollection[0].description}
            blurImage={blurBase64}
            image={newCollection[0].smallTeaserImage}
            imagesAboveFold
            subTitle={newCollection[0].collectionName}
            href={generatePath(Routes.gameDetails, { slug: newCollection[0].slugName })}
          />
          <Box pt={4}>
            <GameSection
              id="new-collection"
              data={newCollection.slice(1, 4)}
              variant="detail"
              imagesAboveFold
              disablePadding
            />
          </Box>

          <Button
            color="primary"
            endIcon={<ArrowForward />}
            component={Link}
            href={generatePath(Routes.collection, { slug: DynamicCollectionType.NewGames })}
            size="small"
          >
            <FormattedMessage id="general.actions.view_all_new_games" />
          </Button>
        </>
      )}
      {!!games?.length && (
        <>
          <Box mb={4} mt={8}>
            <Typography variant="h5" component="h2">
              <FormattedMessage id="discover.browse.all.title" />
            </Typography>
          </Box>
          <GameSection id="browse-all" data={games} variant="detail" disablePadding />
          {hasNextPage && (
            <Button
              color="primary"
              endIcon={
                isFetchingNextPage ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  <ArrowDownward />
                )
              }
              onClick={onFetchNextPage}
              size="small"
            >
              <FormattedMessage id="general.actions.view_more" />
            </Button>
          )}
        </>
      )}
      {!!collections?.length && (
        <Box bgcolor="background.paper" borderRadius={4} padding={4} my={10}>
          <Typography variant="h6" component="h2">
            <FormattedMessage id="discover.browse.collections.title" />
          </Typography>
          <CollectionList collection={collections} removePaddingLeft />
          <Button
            color="primary"
            endIcon={<ArrowForward />}
            component={Link}
            href={generatePath(Routes.collection, { slug: DynamicCollectionType.Collections })}
            size="small"
          >
            <FormattedMessage id="general.actions.show_all_collections" />
          </Button>
        </Box>
      )}
    </>
  );
};

export default StaticDiscover;
