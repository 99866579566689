import { useEffect } from 'react';

import localforage from 'localforage';
import { useCreatePWAEvent } from 'queries/events';

import { Events } from 'constants/events';

import useMounted from './mounted';
import { useAnalytics } from './useAnalytics';

export const useCallPwaEvent = () => {
  const isMounted = useMounted();
  const { createPWAEvent } = useCreatePWAEvent();
  const { send } = useAnalytics();

  useEffect(() => {
    localforage.getItem('PWA_EVENT').then(item => {
      if (!item) {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          localforage.setItem('PWA_EVENT', true);
          send({
            event: Events.loginPWA,
            withUser: true,
          });
          createPWAEvent();
        }
      }
    });
  }, [isMounted, createPWAEvent, send]);
};
