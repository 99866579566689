import { Box, styled, Typography } from '@material-ui/core';

export const VideoOverlay = styled(Box)(({ theme }) => ({
  background: `linear-gradient(180deg, rgba(247, 247, 252, 0) 46.48%, ${theme.palette.background.default} 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 45.83%, #000000 82.29%)`,
  position: 'absolute',
  bottom: -2,
  width: '100%',
  height: '100%',
}));

export const ElevatedTypography = styled(Typography)({
  textShadow:
    '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),0px 1px 3px rgba(0, 0, 0, 0.12)',
});

export const MediaContianer = styled(Box)({
  width: '100%',
  zindex: -1,
  aspectRatio: '16/9',
});
