import { useMemo } from 'react';

import { useAuth } from 'hooks';
import { useGamesTags } from 'queries';
import { useRecoilState } from 'recoil';

import { actionsState } from './util';

export const useInterests = (enabled = false) => {
  const [actions, setActions] = useRecoilState(actionsState);
  const { isLoggedIn, isLoading: isLoadingUser, user } = useAuth();
  const { data: tags, isLoading } = useGamesTags({ enabled });

  const enablePaper = useMemo(() => {
    if (isLoggedIn && !actions.dismissedInterests && !user?.interests?.length && !isLoadingUser)
      return true;
    return false;
  }, [actions.dismissedInterests, isLoggedIn, user?.interests, isLoadingUser]);

  return {
    interests: user?.interests ?? [],
    setActions,
    enablePaper,
    otherInterests: !!tags.length ? tags.filter(tag => !user?.interests?.includes(tag)) : [],
    isLoading,
    actions,
  };
};
