import React from 'react';

import { FeaturedGame } from 'services/api/definitions/collections.definitions';

import { Carousel } from 'components/@common/Carousel';

import GameCarouselItem from '../GameCarouselItem/GameCarouselItem';

interface Props {
  featuredGames: FeaturedGame[];
}

const FeaturedCarousel = ({ featuredGames }: Props) => {
  return (
    <Carousel viewAmount={featuredGames.length}>
      {featuredGames.map((featuredGame, index) => {
        return <GameCarouselItem key={index} featuredGame={featuredGame} />;
      })}
    </Carousel>
  );
};

export default FeaturedCarousel;
