import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { useCollectionById } from 'queries';
import InfiniteScroll from 'react-infinite-scroll-component';

import { SearchResponse } from 'services/api/definitions/search.definitions';

import SearchEmptyState from 'components/@common/Search/SearchEmptyState';
import GameSection from 'components/@game-detail/Section';

interface Props {
  id: string;
  searchData: SearchResponse;
  term?: string;
  onClear: () => void;
}

const StaticCollection: React.FC<Props> = ({ id, searchData, term, onClear }) => {
  const { collection, fetchNextPage, hasNextPage } = useCollectionById(id);

  if (!!term && !searchData.games.length) {
    return (
      <Box mt={10}>
        <SearchEmptyState value={term} onClear={onClear} />
      </Box>
    );
  }

  if (!!term && !!searchData.games.length) {
    return (
      <GameSection
        id="games"
        data={searchData.games}
        variant="detail"
        imagesAboveFold
        disablePadding
      />
    );
  }

  return (
    <InfiniteScroll
      hasMore={hasNextPage}
      next={fetchNextPage}
      dataLength={collection.length}
      loader={
        <Box display="flex" justifyContent="center" py={6}>
          <CircularProgress size={24} />
        </Box>
      }
      style={{ overflow: 'none' }}
    >
      <GameSection id="games" data={collection} variant="detail" imagesAboveFold disablePadding />
    </InfiniteScroll>
  );
};

export default StaticCollection;
