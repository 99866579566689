import React from 'react';

import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[23],
    padding: theme.spacing(4, 6, 6, 6),
  },
  description: {
    opacity: 0.8,
  },
  imageWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 110,
    opacity: 0.75,
  },
}));

interface Props {
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
}

const TutorialCard: React.VFC<Props> = ({ onPrimaryClick, onSecondaryClick }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box mb={1}>
        <Typography variant="h6" color="inherit">
          <FormattedMessage id="rewards.tutorial.title" />
        </Typography>
      </Box>
      <Typography variant="body2" color="inherit" className={classes.description}>
        <FormattedMessage id="rewards.tutorial.description" />
      </Typography>
      <Box display="flex" pt={4}>
        <Button variant="contained" color="primary" onClick={onPrimaryClick}>
          <FormattedMessage id="rewards.tutorial.primary.button" />
        </Button>
        <Box pl={2}>
          <Button color="inherit" onClick={onSecondaryClick}>
            <FormattedMessage id="rewards.tutorial.secondary.button" />
          </Button>
        </Box>
      </Box>
      <Box className={classes.imageWrapper}>
        <Image
          src="/rewards/img-confetti-background-card-1.png"
          width={236}
          height={350}
          priority
          alt="background"
        />
      </Box>
    </Paper>
  );
};

export default TutorialCard;
