import React, { useState } from 'react';

import { Box, Button, CircularProgress, Typography, useTheme } from '@material-ui/core';
import { useAnalytics } from 'hooks';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useExternalGameUrl } from 'queries';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { Routes } from 'routes';

import blurBase64 from 'constants/blurBase64';
import { Events } from 'constants/events';
import { FeaturedGame } from 'services/api/definitions/collections.definitions';
import { TypeOfGame } from 'services/api/definitions/game.definitions';

import VideoPlayer from 'components/@common/VideoPlayer';

import { ElevatedTypography, MediaContianer, VideoOverlay } from './GameCarouselItem.style';

interface Props {
  featuredGame: FeaturedGame;
}

function GameCarouselItem({ featuredGame }: Props) {
  const theme = useTheme();
  const router = useRouter();
  const intl = useIntl();
  const { send } = useAnalytics();
  const [fetchExternalUrl, setFetchExternalUrl] = useState(false);

  const path = generatePath(Routes.game, { slug: featuredGame.slugName });
  const { isLoading, isError } = useExternalGameUrl(
    featuredGame.id,
    featuredGame.webviewRequiresToken ?? false,
    {
      enabled: fetchExternalUrl,
      onSuccess: url => {
        if (!!url) router.push(path);
      },
    },
  );

  const handlePlayClick = async () => {
    send({
      event: Events.gamePlayed,
      data: { game: { slug: featuredGame.slugName } },
      withUser: true,
    });

    if (featuredGame.type === TypeOfGame.webview) {
      setFetchExternalUrl(true);
    } else {
      router.push(path);
    }
  };

  return (
    <>
      <Box position="relative">
        <MediaContianer>
          {featuredGame.videoTeaser ? (
            <VideoPlayer
              url={featuredGame.videoTeaser}
              buttonColor="transparent"
              borderColor={theme.palette.primary.contrastText}
              borderRadius={0}
              buttonPaddingTop="32px"
              absolute
            />
          ) : (
            <Image
              src={featuredGame.largeTeaserImage}
              alt={featuredGame.name}
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL={blurBase64}
            />
          )}
          <VideoOverlay />
        </MediaContianer>
        <Box bottom={0} left={16} position="absolute">
          <Box
            display="flex"
            mb={4}
            onClick={() =>
              router.push(generatePath(Routes.gameDetails, { slug: featuredGame.slugName }))
            }
          >
            <Box
              borderRadius={12}
              height={64}
              width={64}
              position="relative"
              overflow="hidden"
              mr={3}
            >
              <Image
                src={featuredGame.smallTeaserImage}
                alt={`${featuredGame.name} image`}
                layout="fill"
                objectFit="cover"
                blurDataURL={blurBase64}
                placeholder="blur"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              color="primary.contrastText"
            >
              <ElevatedTypography variant="h5">{featuredGame.name}</ElevatedTypography>
              <ElevatedTypography variant="body2">{featuredGame.collectionName}</ElevatedTypography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box px={4} mt={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handlePlayClick}
          fullWidth
          disabled={isError || isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <FormattedMessage
              id="home.carousel.featured_game.play"
              values={{
                price:
                  featuredGame.price.toLowerCase() === 'free'
                    ? intl.formatMessage({ id: 'game.details.price.free' })
                    : featuredGame.price,
              }}
            />
          )}
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" mt={2} mb={4}>
        <Typography variant="body2">
          <FormattedMessage id="home.carousel.featured_game.description" />
        </Typography>
      </Box>
    </>
  );
}

export default GameCarouselItem;
