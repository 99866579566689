import React, { ReactElement } from 'react';

import { Box, CircularProgress, Container, Typography } from '@material-ui/core';
import { useSimpleSearch } from 'hooks';
import _debounce from 'lodash/debounce';
import { GetStaticPaths, GetStaticPropsContext, InferGetStaticPropsType, NextPage } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { QueryKeys, useCollections, useSearch } from 'queries';
import { FormattedMessage, useIntl } from 'react-intl';
import { dehydrate, QueryClient } from 'react-query';
import { Routes } from 'routes';

import { enhancePathsWithLocales } from 'utils/routes.utils';

import { Duration } from 'constants/cache';
import apiService from 'services/api/ApiService';

import DynamicCollection from 'components/@collections/DynamicCollection';
import StaticCollection from 'components/@collections/StaticCollection';
import Background from 'components/@common/Background';
import SearchInput from 'components/@common/Search/SearchInput';
import NotLoggedInCta from 'components/@home/NotLoggedInCta';
import NavigationLayout from 'components/@layout/NavigationLayout/NavigationLayout';
import HeaderSimple from 'components/@navigation/HeaderSimple';

export enum DynamicCollectionType {
  NewGames = 'new-games',
  Collections = 'collections',
  WinRealPrizes = 'win-real-prizes',
  // TrendingNow = 'trending-now',
  // JumpBackIn = 'jump-back-in',
}

//Static collection can be changed at all times by Newcom
//Dynamic collection is a fixed collection filled with an algorithm (ex: new-games)
//atm for dynamic collection we only need to support new-games, i've added the other ones in comments for the future.
const Collection: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({ slug }) => {
  const { push, isFallback } = useRouter();
  const intl = useIntl();
  const { collections } = useCollections();
  const staticMatch = collections.find(collection => collection.slugName === slug);

  const { currentValue, debouncedValue, searchChange, searchClear } = useSimpleSearch();
  const { searchData, isLoading } = useSearch(debouncedValue, staticMatch?.id);

  if (isFallback) {
    return (
      <Box height="100%" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={24} />
      </Box>
    );
  }

  const dynamicTypesArray = Object.values(DynamicCollectionType);
  const isDynamic = dynamicTypesArray.includes(slug as DynamicCollectionType);

  return (
    <>
      <NextSeo
        title={
          isDynamic
            ? intl.formatMessage({ id: `collection.title.${slug}` })
            : intl.formatMessage(
                { id: 'seo.title.collections.slug' },
                { collection: staticMatch?.name },
              )
        }
        //description={intl.formatMessage({ id: 'seo.description.collections' })}
      />
      <Background />
      <Box component="main" height="100%">
        <Container maxWidth="sm">
          <HeaderSimple
            onBackClick={() => push(Routes.home)}
            rightItems={
              <SearchInput
                value={currentValue}
                onChange={searchChange}
                onClear={searchClear}
                fullWidth
              />
            }
          />

          <Box mt={2}>
            <Typography variant="h4" color="textPrimary">
              {isDynamic ? <FormattedMessage id={`collection.title.${slug}`} /> : staticMatch?.name}
            </Typography>
          </Box>
          <NotLoggedInCta
            description={intl.formatMessage({ id: 'collection.account.intro.body' })}
            pt={2}
          />

          <Box mb={6}>
            {isLoading && (
              <Box mt={16} display="flex" alignItems="center" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
            )}
            {!isLoading && dynamicTypesArray.includes(slug as DynamicCollectionType) && (
              <DynamicCollection
                slug={slug}
                term={debouncedValue}
                searchData={searchData}
                onClear={searchClear}
              />
            )}
            {!isLoading &&
              staticMatch &&
              !dynamicTypesArray.includes(slug as DynamicCollectionType) && (
                <StaticCollection
                  id={staticMatch?.id}
                  term={debouncedValue}
                  searchData={searchData}
                  onClear={searchClear}
                />
              )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

// @ts-ignore
Collection.getLayout = function getLayout(page: ReactElement) {
  return <NavigationLayout headerVariant="none">{page}</NavigationLayout>;
};

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const collections = await apiService.getCollections();

  const staticPaths = collections.map(collection => ({ params: { slug: collection.slugName } }));
  const allPaths = [
    ...staticPaths,
    ...Object.values(DynamicCollectionType).map(slug => ({ params: { slug } })),
  ];

  return { paths: enhancePathsWithLocales(allPaths, locales), fallback: true };
};

export const getStaticProps = async ({ params }: GetStaticPropsContext<{ slug: string }>) => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(QueryKeys.new.all(null), () => apiService.getNewCollection());

  await queryClient.prefetchQuery(QueryKeys.winRealPrizes.all(), () =>
    apiService.getWinRealPrizesCollection(),
  );

  // await queryClient.prefetchQuery(QueryKeys.trendingNow.all(), () =>
  //   apiService.getTrendingNowCollection(),
  // );

  return {
    props: {
      // https://github.com/tannerlinsley/react-query/issues/1458
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      slug: params?.slug ?? '',
    },
    revalidate: Duration.ONE_HOUR,
  };
};

export default Collection;
