import React from 'react';

import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Image from 'next/image';
import { Routes } from 'routes';

import { generatePath } from 'utils/routes.utils';

import { CollectionInstance } from 'services/api/definitions/collections.definitions';

import Link from 'components/@navigation/Link';

interface Props {
  collection: CollectionInstance[];
  isAboveFold?: boolean;
  removePaddingLeft?: boolean;
  onClick?: (item: CollectionInstance) => void;
}

const CollectionList: React.FC<Props> = ({
  collection,
  isAboveFold,
  removePaddingLeft,
  onClick,
}) => {
  return (
    <Box ml={removePaddingLeft ? -4 : 0}>
      <List>
        {collection.map(collection => (
          <li key={collection.id}>
            <ListItem
              button
              component={Link}
              onClick={() => {
                onClick?.(collection);
              }}
              href={generatePath(Routes.collection, { slug: collection.slugName })}
            >
              <ListItemIcon>
                <Image
                  src={collection.icon}
                  height={24}
                  width={24}
                  priority={isAboveFold}
                  alt={`${collection.name} icon`}
                />
              </ListItemIcon>
              <ListItemText>{collection.name}</ListItemText>
            </ListItem>
          </li>
        ))}
      </List>
    </Box>
  );
};

export default CollectionList;
