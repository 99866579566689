import { useAuth } from 'hooks';
import { QueryKeys } from 'queries';
import { useQuery, UseQueryOptions } from 'react-query';

import apiService from 'services/api/ApiService';
import { GameTeaser } from 'services/api/definitions/collections.definitions';

export const useJumpBackIn = (options: UseQueryOptions<GameTeaser[]> = { enabled: true }) => {
  const { isLoggedIn } = useAuth();

  const { data, isLoading, isError, refetch } = useQuery(
    QueryKeys.jumpBackIn.all(),
    () => apiService.getJumpBackInCollection(),
    {
      ...options,
      enabled: isLoggedIn && options.enabled,
    },
  );

  return {
    isLoading,
    isError,
    refetch,
    jumpBackIn: (isLoggedIn ? data ?? [] : []).filter(f => f !== null), // TODO: quick fix, api should not return this
  };
};
