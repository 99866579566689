import React from 'react';

import { Button, ButtonProps, makeStyles } from '@material-ui/core';

import GradientTypography from '../GradientTypography';

const useStyles = makeStyles(theme => ({
  disabled: {
    '&& svg': {
      fill: `${theme.palette.action.disabled} !important`,
    },
  },
}));

type Props = Omit<ButtonProps, 'color' | 'variant'>;

const GradientTextButton: React.FC<Props> = ({ children, ...rest }) => {
  const { disabled } = useStyles();

  return (
    <Button color="primary" variant="text" {...rest} classes={{ disabled }}>
      {rest.disabled ? <>{children}</> : <GradientTypography>{children}</GradientTypography>}
    </Button>
  );
};

export default GradientTextButton;
