import { useSnackbar } from 'notistack';
import { QueryKeys } from 'queries';
import { useIntl } from 'react-intl';
import { useInfiniteQuery } from 'react-query';

import apiService from 'services/api/ApiService';

export const useAllGames = (limit: number) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const fetchGames = (params: { queryKey: {}; pageParam?: string }) => {
    let cursor = undefined;
    if (params.pageParam) cursor = params.pageParam.split('?cursor=')[1];

    return apiService.getAllGames(limit, cursor);
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    QueryKeys.allgames.all(),
    fetchGames,
    {
      getNextPageParam: lastPage => lastPage.loadMoreUrl,
      onError: () => {
        enqueueSnackbar(intl.formatMessage({ id: 'general.error.message' }), { variant: 'error' });
      },
    },
  );

  return {
    isLoading,
    isFetchingNextPage,
    games: data ? data?.pages?.map(page => page.games)?.flat(1) : [],
    fetchNextPage,
    hasNextPage,
  };
};
