import { QueryKeys } from 'queries';
import { useQuery, UseQueryOptions } from 'react-query';

import apiService from 'services/api/ApiService';
import { CollectionInstance } from 'services/api/definitions/collections.definitions';

export const useCollections = (options: UseQueryOptions<CollectionInstance[]> = {}) => {
  const { data, isLoading } = useQuery(
    QueryKeys.collections.all(),
    () => apiService.getCollections(),
    {
      ...options,
    },
  );

  return {
    isLoading,
    collections: data ?? [],
  };
};
