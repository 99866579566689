import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, makeStyles, Theme, useTheme } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from 'clsx';
import { darken } from 'polished';
import ReactPlayer from 'react-player';

import WaveAnimation from './components/WaveAnimation';

interface VideoPlayerProps {
  url: string;
  borderColor?: string;
  borderRadius?: number;
  buttonPaddingTop?: string;
  absolute?: boolean;
  buttonColor?:
    | string
    | {
        color1: string;
        color2: string;
      };
  className?: string;
  type?: 'video' | 'audio';
  onReady?: () => void;
}

type StyleType = {
  visible: boolean;
  borderColor?: string;
  borderRadius?: number;
  buttonPaddingTop?: string;
  absolute?: boolean;
  buttonColor:
    | string
    | {
        color1: string;
        color2: string;
      };
};

const useStyles = makeStyles<Theme, StyleType>(theme => ({
  root: ({ borderRadius, absolute }) => {
    const radius = !borderRadius ? `${borderRadius}px` : theme.shape.borderRadius;
    return {
      width: '100%',
      aspectRatio: '16/9',
      position: absolute ? 'absolute' : 'relative',
      overflow: 'hidden',
      background: 'black',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: radius,
    };
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controls: ({ visible, buttonColor, buttonPaddingTop }) => {
    const color1 = typeof buttonColor === 'string' ? buttonColor : buttonColor.color1;
    const color2 = typeof buttonColor === 'string' ? buttonColor : buttonColor.color2;
    return {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1,
      display: 'flex',
      flexDirection: buttonPaddingTop ? 'row' : 'column',
      justifyContent: 'center',
      alignItems: buttonPaddingTop ? 'baseline' : 'center',
      transition: 'opacity 0.2s ease-in-out',
      opacity: visible ? 1 : 0,
      paddingTop: buttonPaddingTop,
      cursor: visible ? 'default' : 'none',
      '&:focus': {
        '& > #play-pause': {
          background: `linear-gradient(99.26deg, ${darken(0.1, color1)} 29.86%, ${darken(
            0.1,
            color2,
          )} 172.64%)`,
        },
      },
    };
  },
  playButton: ({ buttonColor, borderColor }) => {
    const color1 = typeof buttonColor === 'string' ? buttonColor : buttonColor.color1;
    const color2 = typeof buttonColor === 'string' ? buttonColor : buttonColor.color2;
    return {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      width: 60,
      height: 60,
      background: `linear-gradient(99.26deg, ${color1} 29.86%, ${color2} 172.64%)`,
      border: borderColor ? `solid 4px ${borderColor}` : 'none',
      '&:hover': {
        background: `linear-gradient(99.26deg, ${darken(0.1, color1)} 29.86%, ${darken(
          0.1,
          color2,
        )} 172.64%)`,
      },
    };
  },
  playIcon: {
    color: 'white',
    height: 38,
    width: 38,
  },
}));

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  borderColor,
  buttonColor,
  borderRadius,
  absolute,
  buttonPaddingTop,
  className,
  type = 'video',
  onReady,
}) => {
  const [videoIsReady, setVideoIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [canShowControls, setCanShowControls] = useState(true);
  const theme = useTheme();

  const visibleControls = showControls || type === 'audio';

  const classes = useStyles({
    visible: visibleControls,
    borderColor: borderColor,
    buttonColor: buttonColor ?? theme.palette.primary.main,
    borderRadius: borderRadius,
    buttonPaddingTop: buttonPaddingTop,
    absolute: absolute,
  });

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (showControls) {
      timer = setTimeout(() => {
        if (isPlaying) {
          setShowControls(false);
        }
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPlaying, showControls]);

  const handleControlClick = () => {
    const newPlaying = !isPlaying;
    setIsPlaying(newPlaying);
    setShowControls(!newPlaying);

    // Give the user some room for dragging when pressing play again. If this code is not here and he drags the controls will come back.
    if (newPlaying) {
      setCanShowControls(false);
      setTimeout(() => {
        setCanShowControls(true);
      }, 1000);
    }
  };

  const handleOnStart = () => {};

  const handleVideoHasEnded = () => {
    setIsPlaying(false);
    setShowControls(true);
  };

  const handleVideoPaused = () => {
    setIsPlaying(false);
    setShowControls(true);
  };

  const handleOnReady = () => {
    onReady?.();
    setVideoIsReady(true);
  };

  return (
    <div className={clsx(className, classes.root)} data-testid="video-player-test-id">
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        playing={isPlaying}
        onReady={handleOnReady}
        onEnded={handleVideoHasEnded}
        onPause={handleVideoPaused}
        onStart={handleOnStart}
        playsinline
      />

      {videoIsReady ? (
        <div
          className={classes.controls}
          onMouseEnter={canShowControls ? () => setShowControls(true) : undefined}
          onMouseMove={canShowControls ? () => setShowControls(true) : undefined}
          onClick={handleControlClick}
          onKeyPress={e => {
            if (e.key !== 'Tab') {
              handleControlClick();
            }
          }}
          tabIndex={0}
          role="button"
        >
          {type === 'audio' && (
            <Box mb={{ xs: 5, sm: 8 }}>
              <WaveAnimation paused={!isPlaying} />
            </Box>
          )}
          <div id="play-pause" aria-label="play/pause" className={classes.playButton}>
            {isPlaying ? (
              <PauseIcon className={classes.playIcon} />
            ) : (
              <PlayArrowIcon className={classes.playIcon} />
            )}
          </div>
        </div>
      ) : (
        <div className={classes.loading}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
