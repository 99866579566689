import { QueryKeys } from 'queries';
import { useQuery, UseQueryOptions } from 'react-query';

import apiService from 'services/api/ApiService';

export const useGamesTags = (options: UseQueryOptions<string[]> = {}) => {
  const { data, isLoading } = useQuery(QueryKeys.tags.all(), () => apiService.getGamesTags(), {
    ...options,
  });

  return {
    isLoading,
    data: data ?? [],
  };
};
