import React from 'react';

import { Box } from '@material-ui/core';

interface Props {
  amount: number;
  activeStep: number;
  onChangeStep: (step: number) => void;
}

function Pagination({ amount, activeStep, onChangeStep }: Props) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {[...new Array(amount)].map((_, i) => (
        <Box
          key={i}
          mx={2}
          sx={{ borderRadius: '50%', width: '10px', height: '10px' }}
          bgcolor={activeStep === i ? 'primary.main' : 'grey.400'}
          onClick={() => onChangeStep(i)}
        />
      ))}
    </Box>
  );
}

export default Pagination;
