import React from 'react';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { FormattedMessage } from 'react-intl';

import { CollectionInstance } from 'services/api/definitions/collections.definitions';
import { SearchResponse } from 'services/api/definitions/search.definitions';

import CollectionList from 'components/@common/CollectionList';
import SearchEmptyState from 'components/@common/Search/SearchEmptyState';
import GameSection from 'components/@game-detail/Section';
import { SectionItem } from 'components/@game-detail/Section/GameSection';

interface Props {
  searchData: SearchResponse;
  isLoading: boolean;
  term: string;
  viewAll: boolean;
  onClear: () => void;
  onViewAll: () => void;
  onClickCollectionItem?: (item: CollectionInstance) => void;
  onClickGameItem?: (item: SectionItem) => void;
}
const SearchDiscover = ({
  searchData,
  isLoading,
  term,
  viewAll,
  onClear,
  onViewAll,
  onClickCollectionItem,
  onClickGameItem,
}: Props) => {
  if (isLoading) {
    return (
      <Box mt={16} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (!searchData.collections.length && !searchData.games.length) {
    return (
      <Box mt={16}>
        <SearchEmptyState value={term} onClear={onClear} />
      </Box>
    );
  }

  return (
    <>
      {!!searchData.games.length && (
        <Box mt={6}>
          <Typography variant="overline" color="textSecondary">
            <FormattedMessage id="discover.search.games" />
          </Typography>
          <Box mt={2} mb={6}>
            <GameSection
              id="search-games"
              data={viewAll ? searchData.games : searchData.games.slice(0, 3)}
              variant="detail"
              imagesAboveFold
              disablePadding
              onClickItem={onClickGameItem}
            />
          </Box>
          {searchData.games.length > 3 && !viewAll && (
            <Button color="primary" endIcon={<ArrowDownward />} onClick={onViewAll}>
              <FormattedMessage
                id="discover.search.view_all"
                values={{ amount: searchData.games.length }}
              />
            </Button>
          )}
        </Box>
      )}
      {!!searchData.collections.length && (
        <Box mt={6}>
          <Typography variant="overline" color="textSecondary">
            <FormattedMessage id="discover.search.collections" />
          </Typography>
          <Box mt={2} mb={6} bgcolor="background.paper" borderRadius={4}>
            <CollectionList collection={searchData.collections} onClick={onClickCollectionItem} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default SearchDiscover;
