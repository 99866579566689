import styled from 'styled-components';

export const WaveContainer = styled.div<{ paused?: boolean }>`
  height: 35px;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 70px;
  }

  @keyframes animate {
    50% {
      height: 20%;
    }
    100% {
      height: 100%;
    }
  }

  .stroke {
    display: block;
    position: relative;
    background: white;
    height: 100%;
    width: 4px;
    border-radius: 50px;
    margin: 0 2px;
    animation-play-state: ${({ paused }) => (paused ? 'paused' : 'running')};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-name: animate;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 8px;
      margin: 0 5px;
    }
  }

  .stroke:nth-child(1) {
    animation-delay: 0s;
  }
  .stroke:nth-child(2) {
    animation-delay: 0.3s;
    animation-duration: 1s;
  }
  .stroke:nth-child(3) {
    animation-delay: 0.6s;
  }
  .stroke:nth-child(4) {
    animation-delay: 0.9s;
  }
  .stroke:nth-child(5) {
    animation-delay: 0.6s;
    animation-duration: 1s;
  }
  .stroke:nth-child(6) {
    animation-delay: 0.3s;
  }
  .stroke:nth-child(7) {
    animation-delay: 0s;
  }
  .stroke:nth-child(8) {
    animation-delay: 0.9s;
  }
  .stroke:nth-child(9) {
    animation-delay: 0.6s;
    animation-duration: 1.2s;
  }
  .stroke:nth-child(10) {
    animation-delay: 0.3s;
    animation-duration: 0.6s;
  }
  .stroke:nth-child(11) {
    animation-delay: 0.9s;
  }
  .stroke:nth-child(12) {
    animation-delay: 0s;
  }
  .stroke:nth-child(13) {
    animation-delay: 0.3s;
  }
  .stroke:nth-child(14) {
    animation-delay: 0s;
  }
`;
