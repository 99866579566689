import localforage from 'localforage';
import { atom, AtomEffect, DefaultValue } from 'recoil';

interface ActionsState {
  dismissedInterests?: boolean;
  dismissedRewardsTutorial?: boolean;
}

const DEFAULT_STATE: ActionsState = {
  dismissedInterests: false,
  dismissedRewardsTutorial: false,
};

if (typeof window !== 'undefined') {
  localforage.config({
    driver: localforage.LOCALSTORAGE,
    name: 'digi',
    version: 1.0,
  });
}

const localforageActionsEffect: (key: string) => AtomEffect<ActionsState> =
  key =>
  ({ setSelf, onSet }) => {
    if (typeof window === 'undefined') {
      return;
    }

    localforage
      .getItem(key)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((value: any) => {
        if (value != null) {
          setSelf(value);
        } else {
          setSelf({ ...DEFAULT_STATE });
        }
      });

    onSet(newValue => {
      if (newValue instanceof DefaultValue) {
        localforage.removeItem(key);
      } else {
        localforage.setItem(key, newValue);
      }
    });
  };

export const actionsState = atom<ActionsState>({
  key: 'actions',
  default: DEFAULT_STATE,
  effects_UNSTABLE: [localforageActionsEffect('actions')],
});
