import React, { ReactNode, useState } from 'react';

import SwipeableViews from 'react-swipeable-views';

import Pagination from './Pagination';

interface Props {
  children: ReactNode;
  viewAmount: number;
}

function Carousel({ children, viewAmount }: Props) {
  const [activeStep, setActiveStep] = useState(0);

  const handleChangeStep = (step: number) => {
    setActiveStep(step);
  };

  return (
    <>
      <SwipeableViews
        enableMouseEvents
        index={activeStep}
        onChangeIndex={index => setActiveStep(index)}
      >
        {children}
      </SwipeableViews>
      <Pagination amount={viewAmount} activeStep={activeStep} onChangeStep={handleChangeStep} />
    </>
  );
}

export default Carousel;
