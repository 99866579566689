import { useMemo, useState } from 'react';

import { useAuth } from 'hooks';
import { useRecoilState } from 'recoil';

import { actionsState } from './interests/util';

export const useRewardsTutorial = () => {
  const [actions, setActionsRewards] = useRecoilState(actionsState);
  const [open, setOpen] = useState(false);
  const { isLoggedIn, isLoading: isLoadingUser } = useAuth();

  const enableTutorial = useMemo(
    () => isLoggedIn && !actions.dismissedRewardsTutorial && !isLoadingUser,
    [actions.dismissedRewardsTutorial, isLoggedIn, isLoadingUser],
  );

  return {
    setActionsRewards,
    actions,
    enableTutorial,
    setOpen,
    open,
  };
};
