import React from 'react';

import LoadingContentCard, {
  LoadingContentCardProps,
} from 'components/@common/ContentCard/Loading/LoadingContentCard';

import HorizontalList from './HorizontalList';

interface Props extends LoadingContentCardProps {
  id: string;
  omitPadding?: boolean;
  amount?: number;
}

const HorizontalListLoading: React.FC<Props> = ({ id, omitPadding, amount = 7, type, ...rest }) => {
  return (
    <HorizontalList id={id} omitPadding={omitPadding}>
      {[...new Array(amount)].map((_, index) => (
        <LoadingContentCard key={index} type={type} {...rest} />
      ))}
    </HorizontalList>
  );
};

export default HorizontalListLoading;
