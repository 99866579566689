import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import apiService from 'services/api/ApiService';

export const useCreatePWAEvent = (options: UseMutationOptions<unknown, AxiosError> = {}) => {
  const { mutate: createPWAEvent, isLoading } = useMutation(() => apiService.createPWAEvent(), {
    ...options,
  });

  return {
    isLoading,
    createPWAEvent,
  };
};
