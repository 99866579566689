import { QueryKeys } from 'queries';
import { useQuery, UseQueryOptions } from 'react-query';

import { DurationMs } from 'constants/cache';
import apiService from 'services/api/ApiService';
import { FeaturedGame } from 'services/api/definitions/collections.definitions';

export const useFeaturedGames = (options: UseQueryOptions<FeaturedGame[]> = {}) => {
  const { data, isLoading, isError, refetch } = useQuery(
    QueryKeys.featuredGames.all(),
    () => apiService.getFeaturedGames(),
    {
      ...options,
      cacheTime: DurationMs.FIVE_MIN,
      staleTime: DurationMs.FIVE_MIN,
    },
  );

  return {
    isLoading,
    isError,
    refetch,
    featuredGames: data,
  };
};
