import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Button, Container, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Skeleton } from '@material-ui/lab';
import { useAuth, useCallPwaEvent, useInterests, useMounted } from 'hooks';
import { useAnalytics, useSimpleSearch } from 'hooks';
import { useRewardsTutorial } from 'hooks/useRewardsTutorial';
import type { InferGetStaticPropsType, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import {
  QueryKeys,
  useAllGames,
  useCollections,
  useJumpBackIn,
  useNew,
  useSearch,
  useTrendingNow,
  useWinRealPrizes,
} from 'queries';
import { useFeaturedGames } from 'queries/collections/useFeaturedGames';
import { useRecommended } from 'queries/collections/useRecommended';
import { FormattedMessage, useIntl } from 'react-intl';
import { dehydrate, QueryClient } from 'react-query';
import { Routes } from 'routes';

import { generatePath } from 'utils/routes.utils';

import { Duration } from 'constants/cache';
import { Events } from 'constants/events';
import apiService from 'services/api/ApiService';
import { CollectionInstance } from 'services/api/definitions/collections.definitions';

import Background from 'components/@common/Background';
import GradientTextButton from 'components/@common/Button/GradientTextButton';
import HorizontalListLoading from 'components/@common/HorizontalList/HorizontalListLoading';
import PaperAction from 'components/@common/PaperAction/PaperAction';
import SearchInput from 'components/@common/Search/SearchInput';
import GameSection from 'components/@game-detail/Section';
import { SectionItem } from 'components/@game-detail/Section/GameSection';
import { FeaturedCarousel } from 'components/@home';
import { SearchDiscover, StaticDiscover } from 'components/@home';
import NotLoggedInCta from 'components/@home/NotLoggedInCta';
import NavigationLayout from 'components/@layout/NavigationLayout';
import Link from 'components/@navigation/Link';
import TutorialCard from 'components/@rewards-tutorial/TutorialCard';

import { DynamicCollectionType } from './collection/[slug]';

const Tutorial = dynamic(() => import('components/@rewards-tutorial/Tutorial'));

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = _props => {
  const {
    trendingNow,
    isError: isErrorTrendingNow,
    refetch: refetchTrendingNow,
  } = useTrendingNow();
  useCallPwaEvent();
  const { jumpBackIn, isLoading } = useJumpBackIn();
  const { featuredGames } = useFeaturedGames();
  const { recommended } = useRecommended();
  const {
    winRealPrizes,
    isError: isErrorRealPrizes,
    refetch: refetchRealPrizes,
  } = useWinRealPrizes(3);
  const { newCollection, isError: isErrorNew, refetch: refetchNew } = useNew(4);
  const { collections } = useCollections();
  const { games, fetchNextPage, hasNextPage, isFetchingNextPage } = useAllGames(6);
  const isMounted = useMounted();

  const [viewAll, setViewAll] = useState(false);
  const { send } = useAnalytics();
  const { debouncedValue, currentValue, searchChange, searchClear } = useSimpleSearch();
  const { searchData } = useSearch(debouncedValue);

  const { setActions, actions, enablePaper } = useInterests();
  const { setActionsRewards, enableTutorial, setOpen, open } = useRewardsTutorial();
  const { user, isLoggedIn } = useAuth();

  const intl = useIntl();

  const isError = isErrorTrendingNow || isErrorRealPrizes || isErrorNew;

  const refetchData = () => {
    refetchTrendingNow();
    refetchRealPrizes();
    refetchNew();
  };

  const handleClickCollectionSearchItem = (item: CollectionInstance) => {
    send({
      event: Events.searchDetailClick,
      data: { game: { search: currentValue, detail: item.slugName } },
    });
  };

  const handleClickGameSearchItem = (item: SectionItem) => {
    send({
      event: Events.searchDetailClick,
      data: { game: { search: currentValue, detail: item.slugName } },
    });
  };

  // Track empty results
  useEffect(() => {
    if (!searchData.collections.length && !searchData.games.length && !!currentValue.length) {
      send({
        event: Events.searchNotFound,
        data: { game: { search: currentValue } },
      });
    }
  }, [currentValue, searchData, send]);

  return (
    <>
      <NextSeo
        title={intl.formatMessage({ id: 'seo.title.home' })}
        //description={intl.formatMessage({ id: 'seo.description.home' })}
      />
      <Background />
      <Box component="main" mb={6}>
        <Container maxWidth="sm" disableGutters>
          <Box mx={4}>
            <SearchInput
              value={currentValue}
              onChange={searchChange}
              onClear={searchClear}
              fullWidth
            />
            {debouncedValue && (
              <SearchDiscover
                searchData={searchData}
                isLoading={isLoading}
                term={currentValue}
                viewAll={viewAll}
                onClear={searchClear}
                onViewAll={() => setViewAll(prev => !prev)}
                onClickCollectionItem={handleClickCollectionSearchItem}
                onClickGameItem={handleClickGameSearchItem}
              />
            )}
          </Box>

          {!!featuredGames?.length && (
            <Box mt={4}>
              <FeaturedCarousel featuredGames={featuredGames} />
            </Box>
          )}

          {isError ? (
            <Box mx={4} textAlign="center">
              <Typography variant="h4" component="h1">
                <FormattedMessage id="home.data.error.title" />
              </Typography>
              <Box py={2} clone>
                <Typography variant="body1" color="textSecondary">
                  <FormattedMessage id="home.data.error.body" />
                </Typography>
              </Box>
              <Box mt={4}>
                <Button onClick={refetchData} variant="contained" color="primary" fullWidth>
                  <FormattedMessage id="home.data.error.button" />
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box mx={4}>
                <NotLoggedInCta
                  mt={6}
                  title={intl.formatMessage({ id: 'home.account.intro.title' })}
                  description={intl.formatMessage({ id: 'home.account.intro.body' })}
                />
                {enableTutorial && (
                  <Box mt={6}>
                    <TutorialCard
                      onPrimaryClick={() => setOpen(true)}
                      onSecondaryClick={() =>
                        setActionsRewards({ ...actions, dismissedRewardsTutorial: true })
                      }
                    />
                  </Box>
                )}
                {enablePaper && (
                  <Box mt={6}>
                    <PaperAction
                      title={intl.formatMessage(
                        { id: 'interests.paper.title' },
                        { name: user?.username ?? '' },
                      )}
                      description={intl.formatMessage({ id: 'interests.paper.description' })}
                      secondaryButton={
                        <Button
                          color="primary"
                          onClick={() => setActions({ ...actions, dismissedInterests: true })}
                          size="small"
                        >
                          <FormattedMessage id="interests.paper.secondary_button" />
                        </Button>
                      }
                      primaryButton={
                        //@ts-ignore not sure how to fix for now
                        <GradientTextButton href={Routes.interests} component={Link} size="small">
                          <FormattedMessage id="interests.paper.primary_button" />
                        </GradientTextButton>
                      }
                    />
                  </Box>
                )}
              </Box>

              {!!recommended.length && isLoggedIn && (
                <>
                  <Box mt={6} mb={4} mx={4}>
                    <Typography variant="h5" component="h1" color="textPrimary">
                      <FormattedMessage id="home.recommended.title" />
                    </Typography>
                  </Box>
                  <GameSection
                    id="recommended"
                    data={recommended}
                    variant="small"
                    imagesAboveFold
                  />
                </>
              )}

              {!!trendingNow.length && (
                <>
                  <Box mt={10} mx={4}>
                    <Typography variant="h5" component="h1" color="textPrimary">
                      <FormattedMessage id="home.trending_now.title" />
                    </Typography>
                  </Box>

                  <GameSection id="trending_now" data={trendingNow} imagesAboveFold />
                </>
              )}

              {!!jumpBackIn.length && (
                <>
                  <Box mt={6} mb={4} mx={4}>
                    <Typography variant="h5" component="h2" color="textPrimary">
                      <FormattedMessage id="home.jump_back_in.title" />
                    </Typography>
                  </Box>

                  <GameSection
                    id="jump_back_in"
                    data={jumpBackIn}
                    variant="small"
                    imagesAboveFold
                  />
                </>
              )}

              {(!isMounted || isLoading) && (
                <Box mx={4}>
                  <Box mt={6} mb={4} mx={4}>
                    <Skeleton variant="text" width={200} height={27} />
                  </Box>
                  <HorizontalListLoading type="xs" id="jump-back-in-loading" omitPadding />
                </Box>
              )}

              {!!winRealPrizes.length && (
                <Box mx={4}>
                  <Box mt={10} mb={4}>
                    <Typography variant="h5" component="h2" color="textPrimary">
                      <FormattedMessage id="home.win_real_prizes.title" />
                    </Typography>
                  </Box>
                  <GameSection
                    id="win_real_prizes"
                    data={winRealPrizes}
                    variant="detail"
                    disablePadding
                  />
                  <Button
                    color="primary"
                    endIcon={<ArrowForward />}
                    component={Link}
                    href={generatePath(Routes.collection, {
                      slug: DynamicCollectionType.WinRealPrizes,
                    })}
                    size="small"
                  >
                    <FormattedMessage id="general.actions.view_all" />
                  </Button>
                </Box>
              )}
              <Box mx={4}>
                <StaticDiscover
                  newCollection={newCollection}
                  games={games}
                  collections={collections}
                  isFetchingNextPage={isFetchingNextPage}
                  hasNextPage={hasNextPage}
                  onFetchNextPage={fetchNextPage}
                />
              </Box>
            </>
          )}
        </Container>
        {open && <Tutorial isOpen={open} onClose={() => setOpen(false)} />}
      </Box>
    </>
  );
};

// @ts-ignore
Home.getLayout = function getLayout(page: ReactElement) {
  return <NavigationLayout>{page}</NavigationLayout>;
};

export const getStaticProps = async () => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery(QueryKeys.trendingNow.all(false), () =>
    apiService.getTrendingNowCollection(),
  );
  await queryClient.prefetchQuery(QueryKeys.new.all(4), () => apiService.getNewCollection(4));

  await queryClient.prefetchQuery(QueryKeys.winRealPrizes.all(3), () =>
    apiService.getWinRealPrizesCollection(3),
  );

  await queryClient.prefetchQuery(QueryKeys.featuredGames.all(), () =>
    apiService.getFeaturedGames(),
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: Duration.FIVE_MIN,
  };
};

export default Home;
