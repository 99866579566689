import React from 'react';

import { WaveContainer } from './WaveAnimation.style';

interface Props {
  paused?: boolean;
}

const WaveAnimation: React.FC<Props> = ({ paused = false }) => {
  return (
    <WaveContainer paused={paused}>
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
      <span className="stroke" />
    </WaveContainer>
  );
};

export default WaveAnimation;
