import { useSnackbar } from 'notistack';
import { QueryKeys } from 'queries';
import { useIntl } from 'react-intl';
import { useQuery, UseQueryOptions } from 'react-query';

import apiService from 'services/api/ApiService';
import { Game } from 'services/api/definitions/game.definitions';

export const useExternalGameUrl = (
  id: string,
  webviewRequiresToken: boolean,
  options: UseQueryOptions<{ url: Game['url'] }> = {},
) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const { data, isLoading, isError } = useQuery(
    QueryKeys.allgames.externalurl(id),
    () => apiService.getExternalGameUrl(id),
    {
      enabled: !!webviewRequiresToken,
      onError: () => {
        enqueueSnackbar(intl.formatMessage({ id: 'games.error.play' }), { variant: 'error' });
      },
      ...options,
    },
  );

  return {
    isError,
    isLoading,
    data: data ?? { url: '' },
  };
};
