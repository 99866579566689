import { useAuth } from 'hooks';
import { QueryKeys } from 'queries';
import { useQuery, UseQueryOptions } from 'react-query';

import { DurationMs } from 'constants/cache';
import apiService from 'services/api/ApiService';
import { GameTeaser } from 'services/api/definitions/collections.definitions';

export const useTrendingNow = (options: UseQueryOptions<GameTeaser[]> = {}) => {
  const { isLoggedIn } = useAuth();
  const { data, isLoading, isError, refetch } = useQuery(
    QueryKeys.trendingNow.all(isLoggedIn),
    () => apiService.getTrendingNowCollection(),
    {
      ...options,
      cacheTime: DurationMs.FIVE_MIN,
      staleTime: DurationMs.FIVE_MIN,
    },
  );

  return {
    refetch,
    isLoading,
    isError,
    trendingNow: data ?? [],
  };
};
